import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChatAnswersComponent} from "./chat-answers.component";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [ChatAnswersComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  exports: [ChatAnswersComponent]
})
export class ChatAnswersModule { }
