import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {ExecutorService} from "../../services/executor.service";
import {map, takeUntil} from "rxjs/operators";
import {AbstractComponent} from "../abstract.component";

@Component({
  selector: 'chat-answers',
  templateUrl: './chat-answers.component.html',
  styleUrls: ['./chat-answers.component.scss'],
})
export class ChatAnswersComponent extends AbstractComponent implements OnInit {
  answeredQuestions$: Observable<any[]>;

  constructor(private executor: ExecutorService) {
    super();
  }

  ngOnInit() {
    this.answeredQuestions$ = this.executor.candidate$.pipe(
      map(item => item.answers),
      takeUntil(this.destroyed$)
    );
  }

  isOptionSelected(option, options: any[] = null) {
    return (options).indexOf(option) !== -1;
  }
}
