import { Component, OnInit } from '@angular/core';
import {ExecutorService} from "../../services/executor.service";
import {finalize, takeUntil} from "rxjs/operators";
import {LoadingController} from "@ionic/angular";
import {NgxSpinnerService} from "ngx-spinner";
import {AbstractComponent} from "../abstract.component";
import {Router} from "@angular/router";
import {GeneralService} from "../../services/general.service";

@Component({
  selector: 'app-can-apply-footer',
  templateUrl: './can-apply-footer.component.html',
  styleUrls: ['./can-apply-footer.component.scss'],
})
export class CanApplyFooterComponent extends AbstractComponent implements OnInit {

  constructor(public executor: ExecutorService,
              private loadingController: LoadingController,
              public generalService: GeneralService,
              private router: Router,
              private spinner: NgxSpinnerService) {
    super();
  }

  ngOnInit() {
  }

  async apply() {
    await this.spinner.show();
    this.executor.apply({
      full_name: this.executor.getCandidate().full_name,
      email: this.executor.getCandidate().email,
      phone: this.executor.getCandidate().phone,
      phone_obj: this.executor.getCandidate().phone_obj,
      address: this.executor.getCandidate().address,
    }).pipe(takeUntil(this.destroyed$), finalize(() => this.spinner.hide()))
      .subscribe(
        data => {
          if (typeof (window as any).fbq === 'function') {
            (window as any).fbq('track', 'ApplicationSent', { cmpId: this.executor.getCandidate().utm_source });
          }
          this.router.navigate(['/applied-success']);
        },
        error => {
          this.router.navigate(['/tabs/apply'])
        }
      );
  }

}
