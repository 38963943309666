import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { CanApplyFooterComponent } from './can-apply-footer.component';
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RouterModule
  ],
  declarations: [CanApplyFooterComponent],
  exports: [
    CanApplyFooterComponent

  ]
})
export class CanApplyFooterPageModule {}
